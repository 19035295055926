import "./App.css";
import Header from "./components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Hero from "./components/Hero/Hero";
import About from "./Pages/About/About";
import Home from "./Pages/Home/Home";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import LastContent from "./components/LastContent/LastContent";
import Products from "./Pages/Products/Products";
import Contact from "./Pages/Contact/Contact";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/products" element={<Products />} />
          {/* <Route path="/contact-us" element={<Contact />} /> */}


        </Routes>
        <LastContent />
        <Footer />
      </BrowserRouter>
      {/* <Hero/>
      <About/> */}
    </div>
  );
}

export default App;
