import React from "react";
import { Carousel } from "react-bootstrap";
import image1 from "../../assets/Images/image1.png";
import "./Hero.scss";
const Hero = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100 carousel-img" src={image1} alt="First slide" />
        <Carousel.Caption className="caption">
          <h1 className="caption__hThree">Save the environment now to save all...</h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Hero;
