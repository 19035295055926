import React from "react";
import { Carousel } from "react-bootstrap";
import image1 from "../../assets/Images/services.png";
import "./Products.scss";
const Products = () => {
  return (
    <div className="products">
      <Carousel className="products__carousel">
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-img"
            src={image1}
            alt="First slide"
          />
          <Carousel.Caption className="caption"></Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="products__contents">
        <h2 className="products__contents--heading">Our Products & Services</h2>
        <h5>Intro</h5>
        <p>
          Borlaman will provide 3 set of quality dustbins with smart sensor to
          every household,businesses and public places in Ghana in the next 3
          years. The dustbins will be uniquely identified by each district. The
          sensor will communicate to the database.
        </p>
        <p>
          Dustbins will be picked up once in a week. Emergency pick-ups will be
          done upon the alert from the sensor(when the bin gets to 85% filled
          level) or a message or call from the customer.Dustbins can withstand
          changes in weather conditions.
        </p>
        <h5>Our Technology</h5>
        <p>
          Our technology will be AI and ultrasonic waste bin fill level sensors
          for efficiency and improve services transparency.There will be unique
          combination of sensor hardware and analytics software,combined with
          customized reporting and smartphone app.The sensors will identify
          exactly when a pick-up happens and how full the bin is. To Learn more
          about our technology, please give us a call at (437) 556 8740.
        </p>
        <h5>Our Ideal Customers</h5>
        <ol>
            <li>Private Individuals (Home owners)</li>
            <li>Businesses</li>
            <li>Real Estate Developers</li>
            <li>The Government of Ghana</li>
        </ol>
      </div>
    </div>
  );
};

export default Products;
