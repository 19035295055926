import React from "react";
import Hero from "../../components/Hero/Hero";
import Main from "../../components/Main/Main";
import Gallery from "../../components/Gallery/Gallery";

const Home = () => {
  return (
    <div>
      <Hero />
      <Main />
      <Gallery/>
    </div>
  );
};

export default Home;
