import React from "react";
import { Carousel } from "react-bootstrap";
import abt from "../../assets/Images/background2.png";
import "./About.scss";
import Team from "../../components/Team/Team";
import Directors from "../../components/Directors/Directors";

const About = () => {
  return (
    <div className="about">
      <Carousel className="about__carousel">
        <Carousel.Item>
          <img className="d-block w-100 carousel" src={abt} alt="First slide"/>
        </Carousel.Item>
      </Carousel>
      <h2 className="about__heading">About Borlaman </h2>
      <p>
        We are manufacturer and suppliers of high-end, customized dustbins for
        residential and public waste and recycling market Africa and North
        America.We Offer high quality collection services for residential, businesses
        and public waste and recycling market.Dustbins will be designed with
        unique identification for each locality.
      </p>
      

      <h5>Mission Statement</h5>
      <p>
        To solve the sanitation problems in Africa and North America primarily
        by supplying high- tech, unique, eco-friendly, plastic and metal
        customized dustbins to every household, businesses and public places;
        and offer quality and real-time collection service.
      </p>

      <h5>Vision</h5>
      <p>
        Borla Man is the first choice for Ghanaian households, businesses and
        government for customized dustbins in the waste and recycling sector.
      </p>

      <h5>Values</h5>
      <ul>
        <li>
          People: We invest in employees, through ongoing skills training and
          creative days. Treat our staff like our best customers.
        </li>
        <li>
          Innovation: We embrace innovation—incorporating new design methods,
          distribution processes, green inputs and technology to continue
          providing the best-looking, best-performing, eco-friendly, customized
          products. We think about the people who will use our product and
          service; and integrate customer feedback into processes and products.
        </li>
        <li>
          Service: We stand behind our product and service with extended service
          hours, and a 90-days warranty on all items.
        </li>
      </ul>

      <Team />
      <Directors />
    </div>
  );
};

export default About;
