import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../assets/Logo/logo.png';
import './Header.scss';

function Header() {
  return (
    <Navbar
      bg="gray"
      variant="light"
      expand="lg"
      className="navbar"
      collapseOnSelect
    >
      <Container>
        <Navbar.Brand href="/" className="nav-logo">
          <img src={Logo} alt="logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto ">
            <Nav.Link href="/about-us">About Us</Nav.Link>
            <Nav.Link href="/products">Services</Nav.Link>

            {/* <Nav.Link href="/contact-us">Contact Us</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
