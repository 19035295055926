import React from 'react';
import './LastContent.scss';
import Logo from '../../assets/Logo/logo2.png';
import Facebook from '../../assets/Logo/facebook.png';
import { Link } from 'react-router-dom';
const LastContent = () => {
  return (
    <div className="last">
      <div className="last__left">
        <img src={Logo} />
      </div>
      <div className="last__middle">
        <h2>Contact</h2>
        <p>
          <strong>Address:</strong> 50 Empringham Drive, Scarborough, ON <br />
          <strong>Phone Number: </strong> +1 (437) 556 8740 <br />
          <strong>Email:</strong> info@borlamanwaste.ca
        </p>
      </div>
      <Link to="https://www.facebook.com/borlamanwaste" target="_blank">
        <div className="last__right">
          <img src={Facebook} />
          {/* <img src={Facebook} /> */}
        </div>
      </Link>
    </div>
  );
};

export default LastContent;
