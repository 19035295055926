// src/Directors.js
import React from "react";
import "./Directors.scss";
// import Beatrice from "./"

const Directors = () => {
  const directorData = [
    {
      id: 1,
      name: "Dr. Boateng Nimako",
      title: "Board Chairman",
      image: "Dr.jpg",
    },
    {
      id: 2,
      name: "Beatrice Pomaa",
      title: "Director",
      image: "Beatrice.jpg",
    },
  ];

  return (
    <div className="directors-container">
      <h4 className="heading__two">Board of Directors</h4>
      <div className="directors">
        {directorData.map((director) => (
          <div key={director.id} className="director">
            <img
              src={process.env.PUBLIC_URL + `/Images/${director.image}`}
              alt={director.name}
            />
            <div className="director-info">
              <h3>{director.name}</h3>
              <p>{director.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Directors;
