import React from "react";
import "./Main.scss";
import Card from "../Card/Card";
import back from "../../assets/Images/residential.png";
const Main = () => {
  const cardDescription =
    "Our waste collection service guarantees the efficient removal of your waste, contributing to the cleanliness and pleasant ambiance of your community. We want to help keep your community safe.";
  return (
    <>
      <div className="main">
        <h2 className="main__heading"> Welcome To Borlaman</h2>
        <p>
          Borlaman is a Manufacturer and suppliers of high-end, customized
          dustbins for residential and public waste and recycling market Africa
          and North America. We Offer high quality collection services for
          residential, businesses and public waste and recycling market.
        </p>
        <p className="main__italic">
          "We are the Waste Management Solutions for your Homes, Businesses and
          Communities."
        </p>
      </div>
      <Card title="Residential & Business Services" image={back} description={cardDescription} />
    </>
  );
};

export default Main;
