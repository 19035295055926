import React from 'react';
import './Card.scss';
import { Link } from 'react-router-dom';

const Card = ({ title, description, image }) => {
  return (
    <div className="card">
      <div className="row">
        <div className="left-content">
          <h2>{title}</h2>
          <p>{description}</p>
          <Link to='/products'>
            <button className="learn-more-button">Learn More</button>
          </Link>
        </div>
        <div className="right-content">
          <img src={image} alt="Card Image" />
        </div>
      </div>
    </div>
  );
};

export default Card;
