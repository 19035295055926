// src/Team.js
import React from 'react';
import './Team.scss'; // Import the CSS file for styling (create this file in the same folder)

const Team = () => {
  const teamMembers = [
    {
      id: 1,
      name: 'Tony Kwabena Sarpong',
      title: 'President',
      image: 'Tony.jpg', // Put the actual image file in the public folder or use an external URL
    },
    {
      id: 2,
      name: 'Simon Obeng',
      title: 'Chief Operating Officer',
      image: 'Simon.jpg',
    },
    {
      id: 3,
      name: 'Abena Agyakoma Sarpong',
      title: 'Financial Manager',
      image: 'Abena.jpg',
    },
    {
      id: 4,
      name: 'Ruth Arhin',
      title: 'Executive Secretary',
      image: 'Ruth.jpg',
    },
  ];

  return (
    <div className="team-container">
      <h2 className='heading'>Our Team</h2>
      <h5 className='heading__two'>Management Team</h5>
      <div className="team-members">
        {teamMembers.map((member) => (
          <div key={member.id} className="team-member">
            <img src={process.env.PUBLIC_URL + `/Images/${member.image}`} alt={member.name} />
            <div className="member-info">
              <h3>{member.name}</h3>
              <p>{member.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
