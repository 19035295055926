import React from "react";
import "./Gallery.scss"; // Import your SCSS file for styling
import GreenBin from "../../assets/Images/greenbin.jpg"

const Gallery = () => {
  const dustbinImages = [
    "Images/greenbin.jpg",
    "Images/blackbin.jpg",
    "Images/blue.jpg",
    "Images/all.jpg",
    "Images/blue2.jpg",
  ];

  return (
    <div className="gallery">
      <h2>Beautiful Dustbin Gallery</h2>
      <div className="image-grid">
        {dustbinImages.map((image, index) => (
          <img key={index} src={image} alt={`Dustbin ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
